import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, Link } from "react-router-dom";
import { registerUser } from "../service/signUp"; // Make sure this function is correctly implemented
import ReCAPTCHA from 'react-google-recaptcha';  // Import the reCAPTCHA component
import "../style/SignUp.css";

const RegisterPage = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);  // reCAPTCHA state
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = 8;
    const containsLetters = /[a-zA-Z]/.test(password);
    const containsNumbers = /\d/.test(password);

    if (password.length < minLength) {
      return "Password must be at least 8 characters long.";
    } else if (!containsLetters || !containsNumbers) {
      return "Password must contain both letters and numbers.";
    } else {
      return "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    } else {
      setPasswordError("");
    }

    if (!fullName || !email || !phone || !dob || !address || !gender || !profilePicture || !password || !confirmPassword) {
      setShowModal(true);
      setModalMessage("Please fill in all required fields!");
      return;
    }

    if (password !== confirmPassword) {
      setShowModal(true);
      setModalMessage("Passwords do not match!");
      return;
    }

    if (!captchaValue) {
      setShowModal(true);
      setModalMessage("Please verify you are not a robot.");
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("full_name", fullName);
      formData.append("email_address", email);
      formData.append("phone_number", phone);
      formData.append("date_of_birth", dob);
      formData.append("address", address);
      formData.append("gender", gender);
      formData.append("profile_picture", profilePicture);
      formData.append("password", password);
      formData.append("captcha", captchaValue);  // Add captcha value to form data

      const response = await registerUser(formData);

      localStorage.setItem("email", email);

      setModalMessage("Registered successfully!");
      setShowModal(true);

      setTimeout(() => {
        navigate("/signin");
      }, 2000);
    } catch (error) {
      setModalMessage(`Error Registering: ${error.message}`);
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);  // Store the reCAPTCHA response value
  };

  return (
    <section className="register-page">
      <div className="register-container">
        <h1 className="register-title">Register</h1>
        <form onSubmit={handleSubmit}>
          {/* Your existing form fields */}
          <label htmlFor="full-name" className="select-label">Full Name*</label>
          <input
            type="text"
            name="fullName"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
            className="input-field"
          />
          <label htmlFor="email" className="select-label">Email*</label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input-field"
          />
          <label htmlFor="phone-number" className="select-label">Phone Number*</label>
          <input
            type="text"
            name="phone"
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            className="input-field"
          />
          <label htmlFor="dob" className="select-label">Date of Birth*</label>
          <input
            type="date"
            name="dob"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            required
            className="input-field"
          />
          <label htmlFor="address" className="select-label">Address*</label>
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            className="input-field"
          />
          <label htmlFor="gender" className="select-label">Gender*</label>
          <select
            name="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
            className="input-field select-field"
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          <div className="file-upload">
            <label>Profile Picture *</label>
            <input
              type="file"
              name="profilePicture"
              accept="image/*"
              onChange={(e) => setProfilePicture(e.target.files[0])}
              required
              className="input-field"
            />
          </div>
          <label htmlFor="password" className="select-label">Password*</label>
          <div className="password-input-container">
            <input
              type={isPasswordVisible ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-field"
            />
            <span
              className="password-toggle-icon"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
          <label htmlFor="confirmPassword" className="select-label">Confirm Password*</label>
          <div className="password-input-container">
            <input
              type={isConfirmPasswordVisible ? "text" : "password"}
              name="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="input-field"
            />
            <span
              className="password-toggle-icon"
              onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
            >
              {isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>

          {/* Add reCAPTCHA */}
          <div className="signin-form-group">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}  // Your reCAPTCHA site key from environment variable
              onChange={onCaptchaChange}  // Capture reCAPTCHA response
            />
          </div>

          <div className="privacy-policy-text">
            <p>
              By clicking on the register button, you agree to our{" "}
              <Link to="/privacy-policy" className="privacy-policy-link">Privacy Policy</Link>.
            </p>
          </div>

          <button type="submit" className="cta-button" disabled={isLoading}>
            {isLoading ? <ClipLoader size={20} color={"#fff"} /> : "Register"}
          </button>
        </form>

        {/* Modal for messages */}
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <p>{modalMessage}</p>
              <button onClick={() => setShowModal(false)} className="modal-close-btn">Close</button>
            </div>
          </div>
        )}

        <div className="signin-redirect">
          <p>Already have an account? <Link to="/signin">Sign in here</Link></p>
        </div>
      </div>
    </section>
  );
};

export default RegisterPage;
