import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { handleSubscription } from '../service/emailSubsriptionService'; // Import the service
import '../style/Footer.css';

const Footer = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when the request is initiated

        try {
            const response = await handleSubscription(email); // Call backend service
            if (response.success) {
                setMessage('Thank you for subscribing!');
                setEmail('');  // Clear email input on success
            } else {
                setMessage(response.message || 'Subscription failed.');
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
        } finally {
            setLoading(false); // Set loading to false when the request completes
        }
    };

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage(''); // Clear the message after 3 seconds (3000 ms)
            }, 3000);

            return () => clearTimeout(timer); // Clean up the timer if the component unmounts
        }
    }, [message]);

    return (
        <footer className="footer">
            <div className="footer-content">
              
               

                <div className="footer-info">
                    <a href="mailto:info@Lfreturnme.com" className="footer-link">
                        info@lfreturnme.com
                    </a>
                    <a href="tel:+2348023118899" className="footer-link">
                        +234 8 023 118 899
                    </a>
                    <p className="footer-address">
                        KM 10 Airport Rd, AUSTInspire Business Innovation Centre African University of Science & Technology, Galadimawa
                    </p>
                </div>

                <div className="social-media">
                    <a href="https://web.facebook.com/profile.php?id=61560463703667" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a href="https://x.com/LFReturnMe1" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                    <a href="https://www.instagram.com/lfreturnme/?next=%2F" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://www.linkedin.com/company/lf-retune-me/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                </div>
                
                <p>&copy;2024 LFReturnMe. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
