import React from "react";
import "../style/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <section className="privacy-policy-page">
      <div className="privacy-policy-container">
        <h1 className="privacy-policy-title">Terms and Conditions</h1>
        {/* <p><strong>Effective Date: [Insert Date]</strong></p> */}
        <p>
          Welcome to LFReturnMe! By accessing or using our platform, you agree
          to be bound by the following Terms and Conditions. These terms apply
          to all visitors, users, and others who access or use our services.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By registering on LFReturnMe or using our services, you agree to
          comply with and be bound by these Terms and Conditions. If you do not
          agree with any part of the terms, you must not use our services.
        </p>

        <h2>2. Services Provided</h2>
        <p>
          LFReturnMe offers services that help users recover lost items by
          registering them with our platform. We provide:
        </p>
        <ul>
          <li>Item registration</li>
          <li>Reporting lost items</li>
          <li>Connecting finders with owners</li>
          <li>Notifications and updates on recovery status</li>
        </ul>

        <h2>3. User Responsibilities</h2>
        <p>
          By using LFReturnMe, you are responsible for:
        </p>
        <ul>
          <li>Ensuring that all information provided during registration is accurate and up-to-date.</li>
          <li>Properly securing your LFReturnMe tags and attaching them to your items.</li>
          <li>Promptly reporting lost items via our platform for better chances of recovery.</li>
          <li>Picking up or making arrangements to retrieve your found items from designated LFReturnMe offices.</li>
        </ul>

        <h2>4. Finder Responsibilities</h2>
        <p>
          Finders are encouraged to report found items through our platform.
          Finders must:
        </p>
        <ul>
          <li>Provide accurate descriptions of found items.</li>
          <li>Cooperate with LFReturnMe and the item's owner to facilitate the return of the item.</li>
          <li>
            Agree that any form of reward or compensation is voluntary and not
            guaranteed.
          </li>
        </ul>

        <h2>5. Item Recovery and Ownership Verification</h2>
        <p>
          When an item is reported found, LFReturnMe will:
        </p>
        <ul>
          <li>Notify the owner.</li>
          <li>Facilitate the return of the item through our offices or authorized personnel.</li>
        </ul>
        <p>
          Owners are required to provide proof of ownership, such as purchase
          receipts or relevant documents, before claiming their item.
        </p>

        <h2>6. Fees and Subscriptions</h2>
        <p>
          LFReturnMe operates on a subscription model. The following terms
          apply to payments:
        </p>
        <ul>
          <li>Subscriptions are charged monthly or annually depending on the plan.</li>
          <li>Refunds are not available after payment is processed.</li>
          <li>
            LFReturnMe reserves the right to modify subscription fees at any
            time, with prior notice to users.
          </li>
        </ul>

        <h2>7. Cancellation Policy</h2>
        <p>
          Users may cancel their subscriptions at any time, but refunds for
          partial months or years are not provided. Your subscription will
          remain active until the end of the paid period.
        </p>

        <h2>8. Limitation of Liability</h2>
        <p>
          LFReturnMe is a facilitator of item recovery and does not guarantee
          that lost items will always be recovered. LFReturnMe is not
          responsible for any damages or losses, including:
        </p>
        <ul>
          <li>Lost items that are not found.</li>
          <li>Delays in item recovery.</li>
          <li>
            Any incidental, consequential, or indirect damages arising from the
            use of our services.
          </li>
        </ul>

        <h2>9. Privacy Policy</h2>
        <p>
          Your use of our services is also governed by our{" "}
          <a href="/privacy-policy" className="privacy-policy-link">
            Privacy Policy
          </a>
          , which outlines how we collect, store, and use your personal
          information.
        </p>

        <h2>10. Changes to the Terms</h2>
        <p>
          LFReturnMe reserves the right to update or modify these terms at any
          time. Users will be notified of significant changes, and continued
          use of the platform constitutes acceptance of the updated terms.
        </p>

        <h2>11. Termination</h2>
        <p>
          LFReturnMe may suspend or terminate your access to our services if you
          breach any of these terms.
        </p>

        <h2>12. Governing Law</h2>
        <p>
          These Terms and Conditions are governed by the laws of Nigeria. Any
          disputes or claims related to the use of our services will be resolved
          in Nigeria.
        </p>

        <p>
          For any questions or clarifications about these Terms and Conditions,
          feel free to contact us at info@lfreturnme.com.
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
