import { API_SEND_OTP_URL, API_VERIFY_OTP_URL } from './apiEndpoints.js';

export const sendOtpEmail = async (email) => {
    try {
        const payload = { email };

        // Log what is being sent to the backend
        // console.log('Sending data to backend for OTP:', payload);

        const response = await fetch(API_SEND_OTP_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error('Failed to send OTP. Please try again.');
        }

        const data = await response.json();

        // Log what is being returned from the backend
        // console.log('Response received from backend:', data);

        return data;
    } catch (error) {
        // console.error('Error sending OTP:', error);
        throw error;
    }
};
export const verifyOtp = async (email, otp) => {
    try {
        const payload = {
            email: String(email),  // Ensure email is a string
            otp: parseInt(otp),     // Ensure OTP is an integer
        };

        // Log what is being sent to the backend
        // console.log('Sending data to backend to verify OTP:', payload);

        const response = await fetch(API_VERIFY_OTP_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        // Log the status code of the response
        // console.log('Response status:', response.status);

        if (!response.ok) {
            const errorResponse = await response.json();
            // console.error('Error response from backend:', errorResponse);
            throw new Error(errorResponse.message || 'Failed to verify OTP. Please try again.');
        }

        const data = await response.json();

        // Log the response data
        // console.log('Response data received from backend:', data);

        if (data.message) {
            // console.log('OTP verified successfully:', data.message);
            return data.message;  // Return the message from the backend
        } else {
            throw new Error('Unexpected response from backend.');
        }
    } catch (error) {
        // console.error('Error verifying OTP:', error);
        throw error;
    }
};
