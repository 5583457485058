import { API_SIGNIN_URL } from './apiEndpoints'; // Ensure this path is correct

export const signInUser = async (credentials, timeout = 100000) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    try {
        // Use Promise.race to handle the timeout
        const response = await Promise.race([
            fetch(API_SIGNIN_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials),
                signal: controller.signal // Pass the abort signal to fetch
            }),
            new Promise((_, reject) =>
                setTimeout(() => reject(new Error('Request timed out')), timeout)
            )
        ]);

        clearTimeout(timeoutId); // Clear the timeout if the fetch completes in time

        // Parse the response
        const result = await response.json();

        // Log the full response for debugging
        // console.log('Sign-in response:', result);

        if (!response.ok) {
            throw new Error(result.detail || `Failed to sign in: ${response.statusText}`);
        }

        // Check if the result contains user and item data, then save it
        if (result && result.items) {
            // Save user data in local storage
            localStorage.setItem('user', JSON.stringify(result));
        } else {
            // console.error('User data is missing in the sign-in response.');
        }

        return result; // Return the successful result
    } catch (error) {
        if (error.name === 'AbortError') {
            throw new Error('Request was aborted due to timeout.');
        } else {
            // console.error('Error during sign-in:', error);
            throw new Error(error.message || 'An unknown error occurred during sign-in.');
        }
    }
};
