import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { sendOtpEmail, verifyOtp } from "../service/otp";
import "../style/OTP.css";

const OTPPage = () => {
    const [otp, setOtp] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(""); // For success or error messages
    const [resendCount, setResendCount] = useState(0);
    const [resendTimeout, setResendTimeout] = useState(30); // Time in seconds
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false); // To handle the error modal display
    const [showSuccessModal, setShowSuccessModal] = useState(false); // To handle the success modal display

    const navigate = useNavigate();
    const location = useLocation(); // Get the email passed from the SigninPage via state
    const email = location.state?.email; // Retrieve the email

    useEffect(() => {
        // Check if email exists, if not navigate to sign-in page
        if (!email) {
            setMessage("No email found. Please sign in again.");
            setShowModal(true);
            setTimeout(() => {
                navigate("/signin"); // Redirect to sign-in if email is missing
            }, 3000);
        } else {
            sendOtpToUser(); // Send OTP if email is present
        }

        // Set a timeout to clear the email and redirect after 5 minutes of inactivity
        const inactivityTimeout = setTimeout(() => {
            navigate("/signin"); // Redirect to sign-in after 5 minutes
        }, 5 * 60 * 1000); // 5 minutes in milliseconds

        // Listen for back navigation events to clear session data
        const handlePopState = () => {
            navigate("/signin"); // Redirect to sign-in page on back navigation
        };

        window.addEventListener("popstate", handlePopState);

        // Cleanup timeouts and event listeners on component unmount
        return () => {
            clearTimeout(inactivityTimeout);
            window.removeEventListener("popstate", handlePopState);
        };
    }, [email, navigate]);

    const sendOtpToUser = async () => {
        try {
            setIsLoading(true);
            const responseMessage = await sendOtpEmail(email);
            setMessage(responseMessage.message || responseMessage); // Handle the message correctly
        } catch (error) {
            setMessage(error.message || "Failed to send OTP. Please try again.");
            setShowModal(true); // Show the error modal
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendOtp = () => {
        if (resendCount < 3) {
            setResendCount(resendCount + 1);
            setIsResendDisabled(true);
            sendOtpToUser();

            let timer = resendTimeout;
            const intervalId = setInterval(() => {
                timer--;
                setResendTimeout(timer);

                if (timer <= 0) {
                    clearInterval(intervalId);
                    setIsResendDisabled(false);
                    setResendTimeout(30);
                }
            }, 1000);
        } else {
            setMessage("You have reached the maximum number of OTP resends. Please try again later.");
            setShowModal(true); // Show the error modal
        }
    };

    const handleVerifyOtp = async () => {
        try {
            setIsLoading(true);
            const responseMessage = await verifyOtp(email, otp); // Verifying OTP with the email

            setMessage(responseMessage.message || responseMessage); // Handle success message correctly
            setShowSuccessModal(true); // Show the success modal
        } catch (error) {
            setMessage(error.message || "Invalid OTP. Please try again.");
            setShowModal(true); // Show the error modal
        } finally {
            setIsLoading(false);
        }
    };

    const closeModal = () => {
        setShowModal(false); // Close the error modal
    };

    const closeSuccessModal = () => {
        setShowSuccessModal(false); // Close the success modal
        navigate("/signin"); // Redirect to the sign-in page
    };

    const handleBack = () => {
        navigate("/signin"); // Go back to the sign-in page
    };

    return (
        <div className="otp-page">
            <div className="otp-container">
                <h2>OTP Verification</h2>
                {message && <p>{message.message || message}</p>} {/* Show the message */}
                <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                    maxLength={6}
                    className="otp-input input-otp"
                />
                <button className="cta-button-otp" onClick={handleVerifyOtp} disabled={isLoading}>
                    {isLoading ? "Verifying..." : "Verify OTP"}
                </button>
                <button className="cta-button-otp" onClick={handleResendOtp} disabled={isResendDisabled || isLoading}>
                    {isResendDisabled ? `Resend OTP (${resendTimeout}s)` : "Resend OTP"}
                </button>
                <button className="back-button" onClick={handleBack}>
                    Back
                </button>
                <p>{resendCount >= 3 && "Maximum resend attempts reached."}</p>
            </div>

            {/* Error Modal */}
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>Error</h3>
                        <p>{message.message || message}</p>
                        <button className="close-modal-btn" onClick={closeModal}>
                            Close
                        </button>
                    </div>
                </div>
            )}

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="modal-overlay" onClick={closeSuccessModal}>
                    <div className="modal-content">
                        <h3>Success</h3>
                        <p>OTP verified successfully. Now you can sign in.</p>
                        <button className="close-modal-btn" onClick={closeSuccessModal}>
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OTPPage;