import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import About from '../components/About';
import Event from '../components/Events';
import Blog from '../components/Blog';
import Footer from '../components/Footer';
import CommunitySection from '../components/Community';
import Faq from '../components/Faq';
import PricingPackages from '../components/PricingPackages';
import Testimonial from '../components/Testimonial';
import Partners from '../components/Partners';
import Team from '../components/Team';
import HowItWorks from '../components/HowItWorks';

// Scroll effect for header
document.addEventListener('DOMContentLoaded', () => {
    let lastScrollY = window.scrollY;

    window.addEventListener('scroll', () => {
        const header = document.querySelector('.header');
        if (window.scrollY > lastScrollY && window.scrollY > 100) {
            header.classList.add('hidden'); // Scroll down
        } else {
            header.classList.remove('hidden'); // Scroll up
        }
        lastScrollY = window.scrollY;
    });
});

const LandingPage = () => {
    const [isPopupVisible, setPopupVisible] = useState(false); // State for popup visibility

    // Function to handle closing the popup
    const handleClosePopup = () => {
        setPopupVisible(false); // Close popup
    };

    useEffect(() => {
        // Check if the popup has already been shown in this session
        const isPopupAlreadyShown = sessionStorage.getItem('popupShown');
        
        if (!isPopupAlreadyShown) {
            // Show the popup after a small delay when the user opens the site
            setTimeout(() => {
                setPopupVisible(true);
            }, 3000); // 3 second delay to make it less intrusive

            // Mark that the popup has been shown in this session
            sessionStorage.setItem('popupShown', 'true');
        }

        // Add external script for chat functionality
        (function () {
            var s1 = document.createElement("script"),
                s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = "https://embed.tawk.to/6672d93d19f6c616eadbef74/1i0oa175c";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
        })();
    }, []);

    return (
        <>
            <Header />
            <Hero />
            <About />
            <HowItWorks />
            <PricingPackages />
            <Event />
            <Blog />
            <CommunitySection />
            <Faq />
            <Team />
            <Testimonial />

            {/* Automatic popup for Brevo subscription */}
            {isPopupVisible && (
                <div className="Newsletter-popup">
                    <div className="Newsletter-popup-content">
                        <button className="Newsletter-close-btn" onClick={handleClosePopup}>✕</button>
                        <iframe
                            src="https://7b0455a4.sibforms.com/serve/MUIFAFHBhCIdS83Pga5-D53b_6SOOufRdV3n8TjaDgbdZJwhWORhdMEQcoyhdVNk2RT2EFbgayKJ0eNEqnFRqfEpK0HVAc3PvKWFhYBaQKUycy9--ujLqtr7YIpGncYGotaZD1CWMHqhxf3Z8pfV0H0AP-rerR_QAVwDW-hoES7yrpIoRbjfMDTTRYn_geqCs3suex1oKc3oHfvB"
                            frameBorder="0"
                            scrolling="auto"
                            allowFullScreen
                            className="popup-iframe"
                        ></iframe>
                    </div>
                </div>
            )}

            <Partners />
            <Footer />
        </>
    );
};

export default LandingPage;
