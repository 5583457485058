import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchDashboardData } from '../service/tag.js';
import {
    updateItemStatus,
    registerNewItem,
    updateProfile,
    generateSubscriptionLink,
} from '../service/dashboard.js';
import '../style/Dashboard.css';
import { ClipLoader } from 'react-spinners';

const DashboardPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const [user, setUser] = useState(storedUser || null);
    const [currentPage, setCurrentPage] = useState('overview');
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [newItem, setNewItem] = useState({
        item_id: '',
        item_type: '',
        item_name: '',
        tag_id: '',
        item_image: '',
        item_description: '',
        status: '0',
        uuid: storedUser?.uuid || '',
    });
    const [uploadedImageURL, setUploadedImageURL] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [processingTags, setProcessingTags] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [items, setItems] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupType, setPopupType] = useState('');
    const [selectedSubscription, setSelectedSubscription] = useState({});

    // Reference for inactivity timeout
    const inactivityTimeout = useRef(null);

    // Retrieve real access token from sessionStorage
    const accessToken = sessionStorage.getItem('authToken');
    const tokenFromUrl = searchParams.get('token');
    const storedFakeToken = sessionStorage.getItem('fakeToken');

    // Reset inactivity timeout
    const resetInactivityTimeout = () => {
        if (inactivityTimeout.current) {
            clearTimeout(inactivityTimeout.current);
        }
        inactivityTimeout.current = setTimeout(() => {
            handleLogout();
            triggerPopup('You have been logged out due to inactivity.', 'failure');
        }, 2 * 60 * 1000); // 2 minutes in milliseconds
    };

    // Handle logout and cleanup tokens
    const handleLogout = () => {
        localStorage.removeItem('user');
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('fakeToken');
        setUser(null);
        setItems([]);
        setSelectedSubscription({});
        navigate('/signin', { replace: true });
    };

    useEffect(() => {
        // Ensure tokens are present and match
        if (!accessToken || !tokenFromUrl || !storedFakeToken || tokenFromUrl !== storedFakeToken) {
            navigate('/signin', { replace: true });
            return;
        }

        // Reset inactivity timer on any user activity
        const events = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'click', 'scroll'];
        const resetTimer = () => resetInactivityTimeout();
        events.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });
        resetInactivityTimeout();

        // Clean up event listeners on unmount
        return () => {
            events.forEach((event) => {
                window.removeEventListener(event, resetTimer);
            });
            if (inactivityTimeout.current) {
                clearTimeout(inactivityTimeout.current);
            }
        };
    }, [accessToken, tokenFromUrl, storedFakeToken, navigate]);

    useEffect(() => {
        if (!user || !user.uuid) {
            navigate('/signin', { replace: true });
            return;
        }

        window.history.pushState(null, null, window.location.pathname + window.location.search);

        const handlePopState = () => {
            localStorage.removeItem('user');
            sessionStorage.removeItem('authToken');
            sessionStorage.removeItem('fakeToken');
            setUser(null);
            navigate('/signin', { replace: true });
        };

        window.addEventListener('popstate', handlePopState);

        const fetchData = async () => {
            try {
                const dashboardData = await fetchDashboardData(user.uuid, accessToken);  // Passing accessToken to the API
                // console.log(dashboardData);
                setUser(dashboardData);
                setItems(dashboardData.items || []);

                if (!dashboardData.is_verified) {
                    navigate('/otp', { replace: true });
                }
            } catch (error) {
                triggerPopup('Failed to load dashboard data. Please try again later.', 'failure');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [user, accessToken, navigate]);

    // Popup handler to show feedback messages
    const triggerPopup = (message, type) => {
        setPopupMessage(message);
        setPopupType(type);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 10000);
    };

    // Handle profile image upload
    const handleProfileImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setProfileImage(file);
            const reader = new FileReader();
            reader.onload = (e) => setUploadedImageURL(e.target.result);
            reader.readAsDataURL(file);
        } else {
            triggerPopup('Please select a valid image file for your profile picture.', 'failure');
        }
    };

    // Save profile changes to backend
    const handleProfileSave = async () => {
        setProcessingTags({ ...processingTags, profile: true });
        try {
            const response = await updateProfile({ ...user, profile_picture: profileImage });

            if (response.status === 200) {
                const updatedData = await fetchDashboardData(user.uuid, accessToken);
                setUser(updatedData);
                triggerPopup('Profile updated successfully!', 'success');
            } else {
                triggerPopup(`Error updating profile: Status code ${response.status}`, 'failure');
            }
        } catch (error) {
            triggerPopup(`Error updating profile: ${error.message}`, 'failure');
        } finally {
            setProcessingTags({ ...processingTags, profile: false });
        }
    };

    // Register a new item
    const handleRegisterNewItem = async () => {
        const sanitizedItem = {
            ...newItem,
            item_name: newItem.item_name.trim(),
            item_type: newItem.item_type.trim(),
            tag_id: newItem.tag_id.trim(),
            item_description: newItem.item_description.trim(),
        };

        if (!sanitizedItem.item_name || !sanitizedItem.item_type || !sanitizedItem.tag_id || !sanitizedItem.item_description) {
            triggerPopup('All item fields are required.', 'failure');
            return;
        }

        setProcessingTags({ ...processingTags, newItem: true });
        try {
            const response = await registerNewItem(sanitizedItem);

            if (response.status === 200) {
                const updatedData = await fetchDashboardData(user.uuid, accessToken);
                setUser(updatedData);
                setItems(updatedData.items || []);
                triggerPopup('Item registered successfully!', 'success');
                setShowModal(false);
            } else {
                triggerPopup(`Error registering new Tag, Invalid tagid`, 'failure');
            }
        } catch (error) {
            triggerPopup(`Error registering new item: ${error.message}`, 'failure');
        } finally {
            setProcessingTags({ ...processingTags, newItem: false });
        }
    };

    // Handle payment for a tag
    const handlePayment = async (item) => {
        if (!selectedSubscription[item.tag_id]) {
            triggerPopup('Please select a subscription plan.', 'failure');
            return;
        }

        if (processingTags[item.tag_id]) return;

        setProcessingTags({ ...processingTags, [item.tag_id]: true });
        try {
            const selectedPlan = subscriptionPlans.find(
                (plan) => plan.value === selectedSubscription[item.tag_id]
            );

            navigate('/payment', {
                state: {
                    tag: {
                        ...item,
                        email_address: user.email_address,
                    },
                    selectedPlan,
                },
            });
        } finally {
            setProcessingTags({ ...processingTags, [item.tag_id]: false });
        }
    };

    // Manage subscription for a tag
    const handleManageSubscription = async (item) => {
        if (processingTags[item.tag_id]) return;

        setProcessingTags({ ...processingTags, [item.tag_id]: true });

        try {
            const subscriptionCode = item.subscription_code;

            if (!subscriptionCode) {
                triggerPopup('Subscription code not available.', 'failure');
                return;
            }

            const link = await generateSubscriptionLink(subscriptionCode);

            if (link) {
                window.open(link, '_blank');
            } else {
                triggerPopup('Unable to generate subscription management link.', 'failure');
            }
        } catch (error) {
            triggerPopup('Error generating subscription link.', 'failure');
        } finally {
            setProcessingTags({ ...processingTags, [item.tag_id]: false });
        }
    };

    const subscriptionPlans = [
        { label: 'Basic', value: 'basic', amount: 250 },
        { label: 'Standard', value: 'standard', amount: 300 },
        { label: 'Premium', value: 'premium', amount: 1000 },
        { label: 'Passport', value: 'passport', amount: 1500 },
    ];

    const sortedFilteredItems = () => {
        const itemsArray = Array.isArray(items) ? items : Object.values(items || []);

        const sortedItems = itemsArray.sort((a, b) => {
            const isActiveA = a.subscription_status === 'active';
            const isActiveB = b.subscription_status === 'active';
            return isActiveA === isActiveB ? 0 : isActiveA ? -1 : 1;
        });

        if (searchQuery.trim() === '') {
            return sortedItems;
        }

        return sortedItems.filter((item) =>
            item.item_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    if (isLoading) {
        return (
            <div className="loading-container">
                <ClipLoader color={'#123abc'} size={150} />
            </div>
        );
    }

    return (
        <div className="dashboard-container">
            <header className="navbar">
                <p className="navbar-label">LFReturnMe</p>
                <nav>
                    <button onClick={() => setCurrentPage('overview')}>Overview</button>
                    <button onClick={() => setCurrentPage('profile')}>Profile</button>
                    <button onClick={handleLogout}>Logout</button>
                </nav>
            </header>

            {currentPage === 'overview' && (
                <div className="overview">
                    <br />
                    <input
                        type="text"
                        placeholder="Search items..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="input"
                    />
                    <br />
                    <button
                        className="button"
                        onClick={() => {
                            setShowModal(true);
                            setModalType('register');
                        }}
                    >
                        Register New Item
                    </button>

                    <div className="table-container">
                        <h2>Registered Items and Subscriptions</h2>
                        <table className="items-table">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Tag</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Subscription Plan</th>
                                    <th>Action</th>
                                    <th>Subscription Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedFilteredItems().map((item, index) => {
                                    const isActive = item.subscription_status === 'active';
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {item.item_image && (
                                                    <img
                                                        src={item.item_image}
                                                        alt="Item"
                                                        className="item-image"
                                                    />
                                                )}
                                            </td>
                                            <td>{item.item_name}</td>
                                            <td>{item.item_type}</td>
                                            <td>{item.tag_id}</td>
                                            <td>{item.item_description}</td>
                                            <td>
                                                {isActive ? (
                                                    <select
                                                        value={item.status}
                                                        onChange={(e) =>
                                                            updateItemStatus(item.tag_id, e.target.value)
                                                        }
                                                        className="input"
                                                    >
                                                        <option value="0">Active</option>
                                                        <option value="1">Lost</option>
                                                        <option value="2">Found</option>
                                                        <option value="3">Archived</option>
                                                    </select>
                                                ) : null}
                                            </td>
                                            <td>
                                                {isActive ? (
                                                    <span>{item.tier}</span>
                                                ) : (
                                                    <select
                                                        onChange={(e) =>
                                                            setSelectedSubscription((prev) => ({
                                                                ...prev,
                                                                [item.tag_id]: e.target.value,
                                                            }))
                                                        }
                                                        value={selectedSubscription[item.tag_id] || ''}
                                                        className="input"
                                                    >
                                                        <option value="">Select Plan</option>
                                                        {subscriptionPlans.map((plan) => (
                                                            <option key={plan.value} value={plan.value}>
                                                                {plan.label} - {plan.amount} NGN
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                            </td>
                                            <td>
                                                {isActive ? (
                                                    <button
                                                        className="button"
                                                        onClick={() => handleManageSubscription(item)}
                                                        disabled={processingTags[item.tag_id]}
                                                    >
                                                        {processingTags[item.tag_id]
                                                            ? 'Processing...'
                                                            : 'Manage Subscription'}
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="button"
                                                        onClick={() => handlePayment(item)}
                                                        disabled={processingTags[item.tag_id]}
                                                    >
                                                        {processingTags[item.tag_id] ? 'Processing...' : 'Pay for Tag'}
                                                    </button>
                                                )}
                                            </td>
                                            <td>
                                                {isActive ? (
                                                    <span className="active-status">
                                                        <span className="green-dot"> </span> Active
                                                    </span>
                                                ) : (
                                                    <span className="active-status">
                                                        <span className="red-dot"> </span> Inactive
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {currentPage === 'profile' && user && (
                <div className="profile">
                    <br />
                    <br />
                    <img
                        src={uploadedImageURL || user.profile_picture}
                        alt="Profile"
                        className="profile-picture"
                    />
                    <div className="profile-details">
                        <br />
                        <div>
                            <label>Name:</label>
                            <input disabled type="text" value={user.full_name} className="input" />
                        </div>
                        <div>
                            <label>Email:</label>
                            <input disabled type="email" value={user.email_address} className="input" />
                        </div>
                        <div>
                            <label>Phone:</label>
                            <input disabled type="text" value={user.phone_number} className="input" />
                        </div>
                        <div>
                            <label>Address:</label>
                            <input disabled type="text" value={user.address} className="input" />
                        </div>
                        {/* <div>
                            <label>Profile Picture:</label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleProfileImageUpload}
                                className="input"
                            />
                        </div>
                        <button
                            className="button"
                            onClick={handleProfileSave}
                            disabled={processingTags.profile}
                        >
                            {processingTags.profile ? 'Saving...' : 'Save Changes'}
                        </button> */}
                    </div>
                </div>
            )}

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{modalType === 'register' ? 'Register New Item' : 'Update Profile'}</h2>
                        {modalType === 'register' && (
                            <div>
                                <input
                                    type="text"
                                    placeholder="Item ID i.e IMEI Number"
                                    onChange={(e) =>
                                        setNewItem((prev) => ({ ...prev, item_id: e.target.value }))
                                    }
                                    className="input"
                                />
                                <input
                                    type="text"
                                    placeholder="Item Name"
                                    onChange={(e) =>
                                        setNewItem((prev) => ({ ...prev, item_name: e.target.value }))
                                    }
                                    className="input"
                                />
                                <input
                                    type="text"
                                    placeholder="Item Type"
                                    onChange={(e) =>
                                        setNewItem((prev) => ({ ...prev, item_type: e.target.value }))
                                    }
                                    className="input"
                                />
                                <input
                                    type="text"
                                    placeholder="Tag ID"
                                    onChange={(e) =>
                                        setNewItem((prev) => ({ ...prev, tag_id: e.target.value }))
                                    }
                                    className="input"
                                />
                                <input
                                    type="text"
                                    placeholder="Item Description"
                                    onChange={(e) =>
                                        setNewItem((prev) => ({
                                            ...prev,
                                            item_description: e.target.value,
                                        }))
                                    }
                                    className="input"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            setNewItem((prev) => ({ ...prev, item_image: file }));
                                            const reader = new FileReader();
                                            reader.onload = () => setUploadedImageURL(reader.result);
                                            reader.readAsDataURL(file);
                                        }
                                    }}
                                    className="input"
                                />
                                <br />
                                <button
                                    className="button"
                                    onClick={handleRegisterNewItem}
                                    disabled={processingTags.newItem}
                                >
                                    {processingTags.newItem ? 'Registering...' : 'Submit'}
                                </button>
                            </div>
                        )}
                        <br />
                        <button className="button-close" onClick={() => setShowModal(false)}>
                            Close
                        </button>
                    </div>
                </div>
            )}

            {showPopup && (
                <div className={`popup ${popupType}`}>
                    <p>{popupMessage}</p>
                </div>
            )}
        </div>
    );
};

export default DashboardPage;