// dashboardService.js
import { API_DASHBOARD_URL } from './apiEndpoints.js'; // Define this in your apiEndpoints file

export const fetchDashboardData = async (uuid) => {
    try {
        const response = await fetch(`${API_DASHBOARD_URL}/${uuid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch dashboard data. Please try again.');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        // console.error('Error fetching dashboard data:', error);
        throw error;
    }
};
