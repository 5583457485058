import { API_SEND_EMAIL } from '../service/apiEndpoints.js';

// SubscriptionService.js
export const handleSubscription = async (email) => {
    try {
        const response = await fetch(`${API_SEND_EMAIL}`, {
            method: 'POST',
            headers: {
                'accept': 'application/json', // Add this header to match the curl request
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }), // Ensure email is sent correctly in the request body
        });

        if (!response.ok) {
            const errorData = await response.json();
            return { success: false, message: errorData.message || 'Failed to subscribe' };
        }

        const result = await response.json();
        return { success: true, message: result.message || 'Thank you for subscribing!' };

    } catch (error) {
        console.error('Error:', error);
        return { success: false, message: 'An error occurred. Please try again.' };
    }
};
