import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';

const ShopPage = () => {
    const [loading, setLoading] = useState(true);

    const handleIframeLoad = () => {
        setLoading(false);
    };

    return (
        <div style={{ position: 'relative', height: '100vh' }}>
            {/* Loading Spinner */}
            {loading && (
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1000
                }}>
                    <ClipLoader color={"#123abc"} loading={loading} size={150} />
                </div>
            )}

            {/* Iframe */}
            <iframe
                src="https://paystack.shop/lfreturnme-store"
                title="Shop Page"
                style={{ width: '100%', height: '100vh', border: 'none' }}
                onLoad={handleIframeLoad}
            ></iframe>
        </div>
    );
};

export default ShopPage;
