import { API_UPDATE_USER_URL, API_REGISTER_ITEM_URL, API_UPDATE_ITEM_STATUS_URL, API_SUBSCRIPTION_STATUS_URL, CANCLE_SUB_URL, SUBSCRIPTION_CHANGE_EMAIL } from './apiEndpoints';
import axios from 'axios';

// services/dashboardService.js
export const fetchUserData = async (setUser, setItems, setTagsRegistered) => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
        const userItems = Object.values(storedUser.items);
        setUser(storedUser);
        setItems(userItems);
        setTagsRegistered(userItems.length);
    } else {
        window.location.href = "/signin";
    }
}; export const updateProfile = async (user) => {
    try {
        const formData = new FormData();

        // Retrieve the UUID from localStorage in case it's not passed in the user object
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const uuid = storedUser?.uuid || user.uuid;

        // Append all user data dynamically to FormData
        Object.keys(user).forEach((key) => {
            formData.append(key, user[key]);
        });

        // Ensure UUID is included
        formData.append('user_uuid', uuid);

        // Log the data being sent to the backend
        // console.log("Data being sent to the backend:");
        // for (let [key, value] of formData.entries()) {
        //     if (key === 'profile_picture') {
        //         console.log(`${key}: [Binary data - ${value.name}]`);
        //     } else {
        //         console.log(`${key}: ${value}`);
        //     }
        // }

        const response = await fetch(API_UPDATE_USER_URL, {
            method: "PUT",
            body: formData,
        });

        // Log the response status
        // console.log("Response status:", response.status);

        // Check for success responses (200-299 status codes)
        if (!response.ok) {
            const errorResponse = await response.json();
            // console.error("Error response from backend:", errorResponse);
            return response; // Return the response for error handling
        }

        // Parse the successful response (expecting a JSON response)
        let result;
        try {
            result = await response.json();
        } catch (e) {
            // console.warn("Response was not JSON, but the request was successful.");
            return response; // Return the response to indicate success, even if no JSON
        }

        // Log the successful response from the backend
        // console.log("Response data received from the backend:", result);

        // Update local user data
        localStorage.setItem("user", JSON.stringify(result));

        // Return the response
        return response;
    } catch (error) {
        // console.error("Error during profile update:", error);
        throw error; // Rethrow the error so it can be caught by handleProfileSave
    }
};

export const registerNewItem = async (newItem) => {
    try {
        const formData = new FormData();

        // Append all new item data to FormData
        Object.keys(newItem).forEach((key) => {
            formData.append(key, newItem[key]);
        });

        // Log the data being sent to the backend
        // console.log("Data being sent to the backend:");
        // for (let [key, value] of formData.entries()) {
        //     console.log(`${key}: ${value}`);
        // }

        const response = await fetch(API_REGISTER_ITEM_URL, {
            method: "POST",
            body: formData,
        });

        // Log the response status
        // console.log("Response status:", response.status);

        // Check for success responses (200-299 status codes)
        if (!response.ok) {
            const errorResponse = await response.json();
            // console.error("Error response from backend:", errorResponse);
            return response; // Return the response for error handling
        }

        const result = await response.json();

        // Log the successful response from the backend
        // console.log("Response data received from the backend:", result);

        return response; // Return the response to indicate success
    } catch (error) {
        // console.error("Error during item registration:", error);
        throw error; // Rethrow the error to be caught by handleRegisterNewItem
    }
};



export const updateItemStatus = async (tag_id, new_status, setItems) => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (!storedUser || !storedUser.uuid) {
        // console.error("User or UUID is not available.");
        return;
    }

    const url = new URL(API_UPDATE_ITEM_STATUS_URL);
    url.searchParams.append("uuid", storedUser.uuid);
    url.searchParams.append("tagid", tag_id);
    url.searchParams.append("new_status", new_status);

    try {
        const response = await fetch(url.toString(), {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.message || `Failed to update item status: ${response.status}`);
        }


        setItems(prevItems =>
            prevItems.map((item) =>
                item.tag_id === tag_id ? { ...item, status: new_status } : item
            )
        );
    } catch (error) {
        // console.error("Error updating item status:", error);
    }
};




// Function to cancel the subscription
export const cancelSubscription = async (tagId) => {
    try {
        const response = await fetch(`${CANCLE_SUB_URL}/${tagId}`, {
            method: 'POST', // Adjust method type as needed
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to cancel subscription: ${response.status}`);
        }

        return true;  // Return true if the cancellation was successful
    } catch (error) {
        console.error(`Error cancelling subscription for tag ${tagId}:`, error);
        throw error;  // Rethrow error for UI handling
    }
};



// Generate subscription management link
export const generateSubscriptionLink = async (subscriptionCode) => {
    try {
        const response = await axios.get(`https://api.paystack.co/subscription/${subscriptionCode}/manage/link`, {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_KEY}`,
            },
        });
        return response.data.data.link;  // Return the management link
    } catch (error) {
        console.error('Error generating subscription link:', error);
        throw error;
    }
};

export const sendEmailToBackend = async (email) => {
    try {
        const response = await fetch(SUBSCRIPTION_CHANGE_EMAIL, { // Replace with your backend route
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        // Check for success
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to send email to backend: ${errorData.message || response.status}`);
        }

        // Parse and return response
        const data = await response.json();
        // console.log("Email sent to backend:", data);
        return data;
    } catch (error) {
        console.error("Error sending email to backend:", error);
        throw error;
    }
};

// // subscriptionService.js
// export const handleSubscriptionManage = async (tagId, setIsLoading, triggerPopup) => {
//     setIsLoading(true);
//     try {
//         const response = await fetch(`https://api.paystack.co/subscription/${tagId}/manage/link`, {
//             method: 'GET',
//             headers: {
//                 Authorization: PAYSTACK_SECRET_KEY,
//                 'Content-Type': 'application/json'
//             },
//         });

//         const data = await response.json();

//         if (data.status && data.data && data.data.link) {
//             window.open(data.data.link, '_blank');  // Open the subscription management link in a new tab
//         } else {
//             triggerPopup('Error generating link. Please try again.', 'failure');
//         }
//     } catch (error) {
//         triggerPopup(`Error: ${error.message}`, 'failure');
//     } finally {
//         setIsLoading(false);
//     }
// };