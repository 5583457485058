import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import RegisterPage from '../components/RegisterPage';
import SigninPage from '../components/SigninPage';
import DashboardPage from '../components/Dashboard';
import BlogPost from '../components/BlogPost';
import AllBlogs from '../components/AllBlogs';
import MoreEvents from '../components/MoreEvents';
import ForgotPasswordPage from '../components/ForgotPasswordPage';
import ResetPasswordPage from '../components/ResetPassword';
import PricingPackages from '../components/PricingPackages';
import PaymentPage from '../components/paymentPage';
import OTPPage from '../components/otp';
import PrivacyPolicy from '../components/PrivacyPolicy'

// Import useSearchParams to access query parameters
import { useSearchParams } from 'react-router-dom';
import ShopPage from '../components/ShopPage';

// Create a ProtectedRoute component to check for the token
const ProtectedRoute = ({ element: Element, ...rest }) => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    if (!token) {
        // If no token is present, redirect to the signin page
        return <Navigate to="/signin" replace />;
    }

    // If token exists, render the dashboard component
    return <Element {...rest} />;
};

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<LandingPage />} />
                <Route path="/" element={<LandingPage />} />
                <Route path="/signin" element={<SigninPage />} />
                <Route path="/shop" element={<ShopPage />} />

                <Route path="/register" element={<RegisterPage />} />
                {/* Protected Dashboard Route */}
                <Route
                    path="/dashboard"
                    element={<ProtectedRoute element={DashboardPage} />}
                />
                <Route path="/plans" element={<PricingPackages />} />
                <Route path="/blog/:id" element={<BlogPost />} />
                <Route path="/all-blogs" element={<AllBlogs />} />
                <Route path="/more-events" element={<MoreEvents />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/OTP" element={<OTPPage />} />
                <Route path="/payment" element={<PaymentPage />} />
                {/* Dynamic Token Parameter */}
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            </Routes>
        </Router>
    );
};

export default AppRoutes;
